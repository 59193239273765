import React, { useState } from "react"

import BlockWrapper from "../technical/BlockWrapper"
import Headline from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Headline"
import { ArrowDown16, Favorite16, Renew16 } from "@carbon/icons-react"
//import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Button from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Button"

const ProductHeaderBlock = ({ block }) => {
  /* const [variantsIsOpenState, setVariantsIsOpenState] = useState(false) */

  return (
    <BlockWrapper
      block={block}
      blockPadding="py-10"
      blockWidth="container"
      showHeadline="false"
    >
      <div className="flex flex-row flex-wrap justify-between">
        <div className="w-full lg:w-auto">
          <Headline headlineLevel="h4" tagName="h2" headline={block.headline} />
        </div>
        <div className="w-full mt-4 lg:mt-0 lg:w-auto"></div>
      </div>
    </BlockWrapper>
  )
}

export default ProductHeaderBlock
